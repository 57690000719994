<template>
  <div class="homePage">
    <!--    <div class="nav sc-width">-->
    <!--&lt;!&ndash;      <div class="first item" @click="navJump('home')">HOME ></div>&ndash;&gt;-->
    <!--      <div class="second item" @click="navJump('products')"> PRODUCT ></div>-->
    <!--      <div class="third item"> DSM VItamin</div>-->
    <!--    </div>-->
    <div class="company sc-width">
      <div class="logo-company">
        <div class="logo">
          <img :src="company.avatar.url" />
        </div>
        <div class="name">
          <div class="company-name">{{ company.company_name }}</div>
          <!--          <div class="product-name">Natural Vitamin k2 0.45%</div>-->
        </div>
      </div>

      <div class="products">
        <div class="total">
          <font color="#EF7F21">{{ company.products_count }}</font>
          <font color="#53575C">items</font>
        </div>
        <div class="swiper-button-prev prev1" @click="prev"></div>

        <div class="swiper-container swiper-slide-first swiper1">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide swiper-slide-first"
              v-for="pro in companyProduct"
              :key="pro.id"
            >
              <img
                alt=""
                :src="pro.images[0].url"
                class="company-product-img"
                @click="viewProduct(pro)"
              />
            </div>
          </div>
        </div>
        <div class="swiper-button-next next1" @click="next"></div>
      </div>
    </div>
    <div class="search sc-width">
      <div class="company-nav">
        <div class="products  active nav-item">Products</div>
        <div class="company-profile  nav-item" @click="navJump('profile')">
          Company Profile
        </div>
      </div>

      <div class="input">
        <input
          placeholder="Search the product you want..."
          type="input"
          v-model="filter.search"
        />
      </div>
      <div class="search-button" @click="searchProduct">
        <img src="@/assets/search.png" alt="" />
      </div>
      <div></div>
    </div>
    <div class="border"></div>
    <div class="list sc-width">
      <div class="title">
        List of Products
      </div>
      <div class="product-dividor"></div>
      <div class="list-data">
        <div
          class="item"
          :key="product.id"
          v-for="product in list"
          @click="viewProduct(product)"
        >
          <div class="top">
            <img :src="product.images[0].url" />
          </div>
          <div class="bottom">
            <div class="logo">
              <div class="back">
                <img :src="product.company.avatar.url" />
              </div>
            </div>
            <div class="title">{{ product.marketing_name }}</div>
            <div class="company">{{ product.company.company_name }}</div>
            <!--            <div class="label">PRODUCT CATEGORY</div>-->
            <div class="value">{{ product.product_type }}</div>
            <!--                <div class="label">ORIGIN</div>-->
            <!--                <div class="value">China</div>-->
            <div class="tag" :style="{ background: product.tags[0].color }">
              {{ product.tags[0].name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page sc-width">
      <Page
        transfer
        :current="page"
        :page-size="limit"
        :total="total"
        show-elevator
        show-total
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper";

import api from "@/api";
const { _queryCompanyProduct, _queryCompanyCompany } = api;
export default {
  computed: {},
  components: {},
  data() {
    return {
      page: 1,
      limit: 12, // must in 5-20
      total: 0,
      loading: false,
      list: [],
      id: null,
      company: {},
      companyProduct: [],
      filter: { search: "" }
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.filter.search = this.$route.query.search;
  },
  mounted() {
    this.$nextTick(() => {
      // this.initSwiper()
    });
    this.queryCompanyProductList();
    _queryCompanyCompany({ id: this.id })
      .then(response => {
        const { data } = response;
        this.company = data;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
    _queryCompanyProduct({ id: this.id, limit: 30 })
      .then(response => {
        const { data } = response;
        this.companyProduct = data.data;
        // console.log(this.companyProduct)
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
        console.log("query-all-products");
        this.initSwiper();
      });
  },
  methods: {
    searchProduct() {
      this.$router.push({
        path: "/blank",
        query: {
          key: "company/" + this.id + "/products",
          search: this.filter.search
        }
      });
    },
    viewProduct(item) {
      if (item.redirect_url) {
        window.open(item.redirect_url);
      } else if ("Basic" === item.company.level) {
        this.$router.push(`/company/` + item.company.id);
      } else {
        this.$router.push(`/products/` + item.id);
      }
    },
    navJump(path) {
      if ("home" == path) {
        this.$router.push("/index");
      }
      if ("profile" == path) {
        this.$router.push(`/company/${this.id}`);
      }
    },
    pageChange(page) {
      this.page = page;
      this.queryCompanyProductList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryCompanyProductList();
    },
    queryCompanyProductList() {
      this.loading = true;
      if (this.screenWidth) this.limit = 15;
      else this.limit = 12;

      _queryCompanyProduct({
        id: this.id,
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        // autoplay: {
        //   delay: 3000
        // },
        loop: true,
        direction: "horizontal",
        slidesPerView: 4,
        spaceBetween: 10,
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    },
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  .list-data {
    .item {
      width: 357px;
      height: 480px;
      margin-right: 15px;
      .top {
        width: 357px;
        height: 231px;
        img {
          width: 357px;
          height: 231px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
        }

        .title {
          top: -100px;
        }
        .company {
          top: -90px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .swiper-button-prev {
    left: 35px !important;
  }
  .swiper-button-next {
    right: 41px !important;
  }
  .search-wrap {
    .search-box {
      .searchWrap {
        .ivu-select {
          width: 150px;
        }
        .type-title {
          width: 50px !important;
        }
      }
    }
    .country-wrap {
      margin-right: 30px !important;
      .country-title {
        width: 180px !important;
      }
    }
    .filter {
      padding-left: 70px !important;
    }
  }

  .list-data {
    .item {
      width: 287px;
      height: 415px;
      margin-right: 10px;
      .top {
        width: 287px;
        height: 200px;
        img {
          width: 290px;
          height: 200px;
        }
      }
      .bottom {
        .logo {
          width: 140px;
          height: 140px;
          top: -80px;
        }

        .title {
          top: -110px;
        }
        .company {
          top: -100px;
        }
        .label {
          top: -90px;
        }
        .value {
          top: -80px;
        }
        .tag {
          left: 77px;
          bottom: 42px;
        }
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .swiper-button-prev {
    left: -22px !important;
  }
  .swiper-button-next {
    right: -15px !important;
  }
  .search-wrap {
    .search-box {
      .searchWrap {
        /*display: block !important;*/
        .ivu-select {
          width: 130px;
        }
        .type-title {
          width: 50px !important;
        }
      }
      .filter {
        padding-left: 11px !important;
        width: 150px;
        display: block !important;
        flex: 1 !important;
      }
    }
    .country-wrap {
      margin-right: 30px !important;
      .country-title {
        width: 180px !important;
      }
    }
  }
  .list-data {
    .item {
      width: 355px;
      height: 480px;
      margin-right: 8px;
      .top {
        width: 355px;
        height: 231px;
        img {
          width: 357px;
          height: 231px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
        }

        .title {
          top: -100px;
        }
        .company {
          top: -90px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .list-data {
    .item {
      width: 357px;
      height: 480px;
      .top {
        width: 357px;
        height: 231px;
        img {
          width: 357px;
          height: 231px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
        }

        .title {
          top: -100px;
        }
        .company {
          top: -90px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
.homePage {
  background: #ffffff;
  margin-top: 153px;
}
.nav {
  display: flex;
  /*width:1500px;*/
  margin: 30px auto;
  padding-top: 30px;
  div {
    height: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #53575c;
    line-height: 17px;
    cursor: pointer;
  }
}
.company {
  display: flex;
  justify-content: space-between;
  /*width: 1500px;*/
  margin: 0 auto;
  padding-top: 20px;
  .logo-company {
    .logo {
      display: inline-block;
      /*width: 231px;*/
      height: 111px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      display: inline-block;
      .company-name {
        height: 28px;
        font-size: 28px;
        font-weight: 500;
        color: #ef842d;
        position: relative;
        top: -35px;
        left: 47px;
      }
      .product-name {
        height: 23px;
        font-size: 22px;
        font-weight: 700;
        color: #252729;
        position: relative;
        top: -12px;
        left: 47px;
      }
    }
  }
}
.search {
  display: flex;
  margin: 10px auto 0 auto;
  background: #ffffff;
  /*border: 1px solid #8A9199;*/
  /*border-radius: 5px;*/
  .company-nav {
    height: 47px;
    width: 915px;
    background: #ffffff;
    font-size: 18px;
    font-weight: 500;
    color: #53575c;
    /*line-height: 17px;*/
    padding-top: 10px;
    border: 1px solid #8a9199;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
    div {
      display: inline-block;
      cursor: pointer;
    }
    .home {
      margin: 0 65px 0 41px;
    }
    .products {
      margin: 0 65px 0 20px;
    }
    .active {
      color: #ef842d;
    }
    .company-profile {
      margin: 0 65px 0 0px;
    }
    /*border:none;*/
  }
  .input {
    border: 1px solid #8a9199;
    input {
      height: 45px;
      width: 440px;
      border: none;
      /*border-left: 1px solid #8A9199;*/
      padding-left: 10px;
      outline: none;
      font-size: 18px;
    }
  }
  .search-button {
    width: 143px;
    height: 47px;
    background: #ef7f21;
    border-radius: 0px 5px 5px 0px;
    text-align: center;
    padding-top: 8px;
  }
}
.border {
  width: 100%;
  height: 20px;
  background: #f0f0f0;
  margin: 40px 0 60px 0;
}
.company-info {
  margin: 0 auto;
  .info {
    display: flex;
    justify-content: space-between;
    .basic {
      .name {
        font-size: 28px;

        font-weight: 500;
        color: #252729;
      }
      .detail {
        display: flex;
        .item {
          div {
            display: flex;
          }
          .label {
            font-size: 16px;

            font-weight: 400;
            color: #ef842d;
          }
          .value {
            font-size: 16px;

            font-weight: 400;
            color: #252729;
          }
        }
      }
      .operate {
        display: flex;
        .chat {
          width: 200px;
          height: 40px;
          background: #ef842d;
          border-radius: 5px;
          font-size: 18px;

          font-weight: 500;
          color: #ffffff;
        }
        .question {
          width: 200px;
          height: 40px;
          border: 1px solid #ef842d;
          border-radius: 5px;
          font-size: 18px;

          font-weight: 500;
          color: #ef842d;
        }
      }
    }
    .desc {
      .title {
        width: 865px;
        height: 50px;
        background: #3c3853;
        border-radius: 5px;
      }
      .content {
        font-size: 16px;

        font-weight: 400;
        color: #333333;
        line-height: 30px;
      }
    }
  }
}
.list {
  /*width: 1500px;*/
  margin: 0 auto;
  .title {
    font-size: 36px;

    font-weight: 500;
    color: #252729;
    line-height: 17px;
  }
  .product-dividor {
    height: 1px;
    background: #cccccc;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .list-data {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
    margin-left: 10px;
    background: #f2f2f2;
    padding-bottom: 20px;
    .item {
      background: #ffffff;
      border-radius: 5px;
      margin-top: 24px;
      cursor: pointer;
      .top {
        border-radius: 5px;
      }
      .bottom {
        .logo {
          /*background: #FFFFFF;*/
          position: relative;
          left: 10px;
          .back {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            background: #ffffff;
            padding-top: 10px;
            img {
              width: 70px;
              height: 70px;
            }
          }
        }
        .title {
          font-size: 20px;
          font-weight: 500;
          color: #252729;
          position: relative;
          left: 22px;
        }
        .company {
          font-size: 16px;
          font-weight: 400;
          color: #808080;
          position: relative;
          left: 22px;
        }
        .label {
          font-size: 12px;
          font-weight: 500;
          color: #808080;
          position: relative;
          left: 22px;
        }
        .value {
          width: 44px;
          height: 9px;
          font-size: 16px;
          font-weight: 400;
          color: #808080;
          position: relative;
          left: 22px;
        }
        .tag {
          width: 122px;
          height: 32px;
          background: #ef842d;
          border-radius: 5px;
          position: relative;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
.page {
  display: flex;
  justify-content: center;
  margin: 55px auto;
}
.swiper-slide {
  width: 122px !important;
  height: 82px;
  .company-product-img {
    width: 102px;
    height: 82px;
  }
}
.swiper-button {
  position: relative;
  left: 6px;
  top: 90px;
  font-size: 20px;
  --swiper-navigation-size: 20px;
  --swiper-navigation-color: #ff6600;
  left: -30px;
  top: -45px;
  color: #b2b2b2;
}
.swiper-button-next {
  position: relative;
  right: 3px;
  top: 90px;
  font-size: 20px;
  --swiper-navigation-size: 20px;
  --swiper-navigation-color: #ff6600;
  left: 508px;
  top: -55px;
  color: #b2b2b2;
}
.company {
  .products {
    .total {
      text-align: right;
      margin-right: 30px;
    }
    width: 400px;
    .swiper1 {
      margin-right: 30px;
    }
  }
}
.prev1 {
  position: relative;
  left: 6px;
  top: 90px;
  font-size: 20px;
  --swiper-navigation-size: 20px;
  --swiper-navigation-color: #ff6600;
  left: -30px;
  top: 52px;
  color: #b2b2b2;
}
.next1 {
  position: relative;
  right: 3px;
  top: 90px;
  font-size: 20px;
  --swiper-navigation-size: 20px;
  --swiper-navigation-color: #ff6600;
  left: 382px;
  top: -40px;
  color: #b2b2b2;
}
</style>
